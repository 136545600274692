import React from 'react'
import Layout from '../components/layout'
import ContentBlock from "../components/content-block";
import SideByStretch from "../components/side-by-stretch";

const IndexPage = () => (
  <Layout>
    <SideByStretch imgSrc="/images/uploads/hands.jpg">
      <h2>Supporting mental wellness on your journey to a higher&nbsp;self</h2>
    </SideByStretch>
    <ContentBlock wrapper>
      <p>We are a practice that specializes in women’s mental health and believe that everyone deserves support during times of uncertainty.</p>
      <p><a href="/contact">Let's talk!</a></p>
    </ContentBlock>
  </Layout>
)

export default IndexPage
